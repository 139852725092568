import React from "react";
import Layout from "./index";
import { addLocaleData } from "react-intl";

import messages from "../data/messages/en";
import en from "react-intl/locale-data/en";
import "intl/locale-data/jsonp/en-US";
import header_content from "../data/static-pages/header/en";
import footer_content from "../data/static-pages/footer/en";

addLocaleData(en);

export default (props) => {
  return (
    <Layout
      {...props}
      i18nMessages={messages}
      mobileWidgetStatus={props.mobileWidgetStatus}
      setMobileWidgetStatus={props.setMobileWidgetStatus}
      header_content={header_content}
      footer_content={footer_content}></Layout>
  );
};
